import React from 'react'

import Layout from '../components/layout'
import ButtonLink from '../components/ButtonLink'
import { H1 } from '../components/Headlines'
import { Hero } from '../components/Hero'
import Section from '../components/Section'
import { GallerySection } from '../components/GallerySection'
import { TestDrive } from '../components/TestDrive'
import SimpleSlider from '../components/Slider'

import { ImageBox } from '../components/ImageBox'

import Individualization from '../images/a-class-limousine/highlights/individualization.jpg'
import HeadUpDisplay from '../images/a-class-limousine/highlights/head-up-display.jpeg'
import ArtificialIntelligence from '../images/a-class-limousine/highlights/artificial-intelligence.jpeg'
import Linguatronic from '../images/a-class-limousine/highlights/linguatronic.jpg'
import Touchpad from '../images/a-class-limousine/highlights/touchpad-touchcontrol.jpeg'
import Charging from '../images/a-class-limousine/highlights/wireless-charging.jpeg'
import MBUXAugmentedReality from '../images/a-class-limousine/highlights/MBUX-augmented-reality.jpeg'
import SmartphoneIntegration from '../images/a-class-limousine/highlights/smartphone-integration.jpeg'

import HeaderImage from '../images/a-class-limousine/header-a-class-limousine.jpeg'

import InterieurImage from '../images/a-class-limousine/gallery/interieur-teaser-a-class-limousine.jpeg'
import ExterieurImage from '../images/a-class-limousine/gallery/exterieur-teaser-a-class-limousine.jpeg'

import InterieurImageGallery1 from '../images/a-class-limousine/gallery/gallery1-interieur-a-class-limousine.jpeg'
import InterieurImageGallery2 from '../images/a-class-limousine/gallery/gallery2-interieur-a-class-limousine.jpeg'
import InterieurImageGallery3 from '../images/a-class-limousine/gallery/gallery3-interieur-a-class-limousine.jpeg'
import InterieurImageGallery4 from '../images/a-class-limousine/gallery/gallery4-interieur-a-class-limousine.jpeg'
import InterieurImageGallery5 from '../images/a-class-limousine/gallery/gallery5-interieur-a-class-limousine.jpeg'

import ExterieurImageGallery1 from '../images/a-class-limousine/gallery/gallery1-exterieur-a-class-limousine.jpeg'
import ExterieurImageGallery2 from '../images/a-class-limousine/gallery/gallery2-exterieur-a-class-limousine.jpeg'
import ExterieurImageGallery3 from '../images/a-class-limousine/gallery/gallery3-exterieur-a-class-limousine.jpeg'
import ExterieurImageGallery4 from '../images/a-class-limousine/gallery/gallery4-exterieur-a-class-limousine.jpeg'

const GalleryImagesInterieurSrc = [
  InterieurImageGallery1,
  InterieurImageGallery2,
  InterieurImageGallery3,
  InterieurImageGallery4,
  InterieurImageGallery5,
]

const GalleryImagesExterieurSrc = [
  ExterieurImageGallery1,
  ExterieurImageGallery2,
  ExterieurImageGallery3,
  ExterieurImageGallery4,
]

const AClassLimousine = () => (
  <Layout>
    <Hero bgImage={HeaderImage} />
    <Section align="center">
      <H1>
        Kompakter Einstieg in die Premium-Limousinen-Welt von Mercedes-Benz. Die
        neue A-Klasse.
      </H1>
      <p>
        Die Kompaktwagen-Familie von Mercedes-Benz wächst weiter: Ende 2018
        kommt die A-Klasse Limousine auf den Markt. Das viertürige
        Stufenheckmodell hat einen Radstand von 2.729 Millimetern und besitzt
        die Proportionen einer dynamischen und zugleich kompakten Limousine mit
        kurzen Überhängen vorne und hinten. Bei der Kopffreiheit im Fond setzt
        sie sich an die Spitze ihres Segments. Darüber hinaus verfügt die
        Limousine über die bekannten A-Klasse Tugenden. Dazu zählen moderne,
        effiziente Motoren, das hohe Sicherheitsniveau dank neuester
        Fahrassistenz-Systeme mit S-Klasse Funktionen und das intuitiv zu
        bedienende, lernfähige Infotainment-System MBUX – Mercedes-Benz User
        Experience. Mit einem cw-Wert von 0,22 und einer Stirnfläche von 2,19 m²
        erreicht die neue A-Klasse Limousine den niedrigsten Luftwiderstand
        aller Serienfahrzeuge weltweit und verteidigt damit den Weltrekord des
        CLA Coupés.
      </p>
      <ButtonLink to="/probefahrt/">weiter zur Terminauswahl</ButtonLink>
    </Section>
    <GallerySection
      InterieurImageSrc={InterieurImage}
      ExterieurImageSrc={ExterieurImage}
      GalleryImagesInterieur={GalleryImagesInterieurSrc}
      GalleryImagesExterieur={GalleryImagesExterieurSrc}
    />
    <Section align="default" variant="grey">
      <H1>Was macht die neue A-Klasse so besonders?</H1>
      <SimpleSlider>
        <ImageBox
          imagesrc={Linguatronic}
          title="Sprachbediensystem LINGUATRONIC"
          headline="Es gibt reichlich Redebedarf."
          description="Dank ganz neuer Art der LINGUATRONIC hört die neue A-Klasse Limousine aufs Wort. Aber noch viel mehr: Sie versteht Sie, ohne vorher Kommandos lernen zu müssen. Und spricht mit Ihnen. SMS vorlesen oder diktieren und abschicken? Kein Problem. Sie überprüft für Sie das Wetter am Zielort, wechselt den Radiosender oder bringt Sie auf schnellstem Weg nach Hause. Nur zwei Worte genügen: „Hey Mercedes“. Schon ist die neue A-Klasse Limousine ganz Ohr."
          subDescription="Nur erhältlich als Paketbestandteil in Verbindung mit den Ausstattungen Erweiterte Funktionen MBUX und Touchpad."
        />
        <ImageBox
          imagesrc={Individualization}
          title="Individualisierung"
          headline="Nie angepasst. Außer an Sie und Ihr Leben."
          description="Mit der neuen A-Klasse Limousine und MBUX (Mercedes-Benz User Experience) ist ein bisschen mehr möglich. Deshalb können Sie nicht nur die Ambientebeleuchtung an Ihre Stimmung anpassen, sondern auch die Anzeigenstile auf dem Kombiinstrument und dem Display des Multimediasystems. Ganz individuell und intuitiv zum Beispiel über die Touch-Control-Buttons am Lenkrad."
        />
        <ImageBox
          imagesrc={ArtificialIntelligence}
          title="Mercedes-Benz User Experience"
          headline="Kennt den Weg zu Ihrer Wohnung. Und Ihre Gewohnheiten."
          description="MBUX, die neue Mercedes-Benz User Experience, macht die neue A-Klasse Limousine intelligenter als je zuvor. Jeden Tag lernt sie dazu und vor allem ihren Fahrer ein bisschen besser kennen. Sie kann sich Ihre Lieblingssongs und den Weg zur Arbeit merken. Dann stellt sie automatisch den richtigen Radiosender ein oder zeigt Ihnen einen schnelleren Weg, wenn auf dem üblichen Stau ist. Ganz von selbst und ganz selbstverständlich, sofern man es wünscht."
        />
        <ImageBox
          imagesrc={Touchpad}
          title="Touchpad und Touch Control"
          headline="Die Bedienung mit dem gewissen Touch."
          description="Stellen Sie sich vor, Sie könnten mit dem kleinen Finger ein ganzes Auto bedienen. Perfekt, oder? Dann stellen wir Ihnen jetzt das Touchpad vor, mit dem Sie fast alles einstellen können. Zum Beispiel das Navigationssystem oder die Ambientebeleuchtung. Selbst Ihre Handschrift kann die berührungssensitive Oberfläche mühelos erkennen. Noch einfacher und noch direkter geht’s mit dem Touchscreen. Hier reicht eine Bewegung, schon macht es klick."
        />
        <ImageBox
          imagesrc={HeadUpDisplay}
          title="Head-up-Display"
          headline="Typisch A-Klasse, den Blick immer nach vorne richten."
          description="Augen geradeaus geht mit dem Head-up-Display in der neuen A-Klasse Limousine ganz automatisch. Denn damit werden alle relevanten Informationen, wie Geschwindigkeit, Tempolimits oder Navigationshinweise, direkt in Ihr Sichtfeld projiziert. Virtuell und vollfarbig. Die Inhalte des Anzeigefeldes lassen sich anpassen und per Memory-Funktion abspeichern."
        />
        <ImageBox
          imagesrc={Charging}
          title="Kabelloses Laden"
          headline="Die smarte Art, Ihr Smartphone mitzunehmen."
          description="In der neuen A-Klasse Limousine laden Sie Ihr Smartphone auf, indem Sie es in der Mittelkonsole ablegen. Ganz einfach, ganz ohne Kabel und mit Qi-Standard. Und das Beste ist: Dank Near Field Communication (NFC) connectet sich Ihr Smartphone sofort mit der A-Klasse Limousine ohne viele Bedienschritte oder die Eingabe eines Codes."
        />
        <ImageBox
          imagesrc={MBUXAugmentedReality}
          title="MBUX Augmented Reality für Navigation"
          headline="Manchmal muss nicht die Welt verändert werden, sondern nur der Blick auf sie."
          description="Mit der neuen A-Klasse Limousine erhält man ganz neue Aussichten. Zum Beispiel auf die Straße. Mithilfe einer speziellen Kamera gibt sie die Umgebung als Video oder Standbild auf dem Display des Multimediasystems wieder. Auf eine innovative und informative Weise. Sie ergänzt virtuelle Objekte oder Markierungen und erleichtert so zum Beispiel das Auffinden Ihres Ziels. Ganz intuitiv und ganz schön intelligent."
        />
        <ImageBox
          imagesrc={SmartphoneIntegration}
          title="Smartphone Integration"
          headline="Sieht aus wie ein Lenkrad. Kann so viel mehr."
          description="Auch SMS verschicken oder durch die Kontakte zu scrollen ist kein Problem. In der neuen A-Klasse Limousine können Sie Ihr Smartphone benutzen, ohne es in die Hand zu nehmen. Per USB oder NFC verbinden Sie es. Per Touch-Control-Buttons am Lenkrad können Sie dann ganz bequem auf dem Display des Multimediasystems fast alle Apps und Funktionen bedienen. Wie von Geisterhand und im Handumdrehen."
        />
      </SimpleSlider>
    </Section>
    <TestDrive />
  </Layout>
)

export default AClassLimousine
